import React, { Component } from 'react'
import PropTypes from 'prop-types'

class StudentsTickets extends Component {

  render () {
    const { studentTickets } = this.props
    const ticketItems = studentTickets.map( (item, index) => {
      let name = item.product_name;

      if (item.season_pass_ticket) {
        name += " (" + item.course_name + ")"
      }

      let start_use_date;
      if (item.season_pass_ticket) {
        start_use_date = item.creation_date;
      } else if (item.oldest_transaction_date) {
        start_use_date = item.oldest_transaction_date
      } else {
        start_use_date = "ei otettu vielä käyttöön";
      }
   
      let valid_until_date;
      if (item.valid_until_from_first_use) {
        valid_until_date = item.valid_until_date;
      } else {
        valid_until_date = "(" + item.valid_until_date + ")";
      }

      return (
        <tr key = { index } data-student-ticket-id = { item.id } >
          <td>{ item.id }</td>
          <td>{ name }</td>
          <td>{ item.remaining_classes } kpl</td>
          <td>{ item.product_price_category_price ? item.product_price_category_price : item.product_price }</td>
          <td>{ start_use_date }</td>
          <td>{ valid_until_date }</td>
        </tr>
      )
    })

    const studentTicketsTableHeader = (
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>{ I18n.t('students_tickets.product_name') }</th>
          <th>{ I18n.t('activerecord.attributes.student_ticket.remaining_classes') }</th>
          <th>Tuotteen arvo</th>
          <th>{ I18n.t('activerecord.attributes.student_ticket.valid_until_from_purchase') }</th>
          <th>{ I18n.t('activerecord.attributes.student_ticket.valid_until_from_first_use') }</th>
        </tr>
      </thead>
    )

    return(
      <table className = 'table table-striped table-hover table-condensed no-wrap' >
        { studentTicketsTableHeader }
        <tbody>
          { ticketItems }
        </tbody>
      </table>
    )
  }
}

export default StudentsTickets
