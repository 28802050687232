import React, { Component} from "react"
import PropTypes from "prop-types"
import ShoppingCart from './ShoppingCart';
import Terms from './Terms';

class Products extends Component {
	constructor(props) {
		super(props);

		this.state = {
			basicProducts: props.basicProducts,
			specialProducts: props.specialProducts,
			campsAndIntensiveProducts: props.campsAndIntensiveProducts,
			shoppingCartItems: [],
			shoppingCartIdCounter: 0,
			shoppingState: 0,
			flashNotice: {
				visible: false,
				active: false,
				text: ''
			}
		};

		this.onAddToCart = this.onAddToCart.bind(this);
		// I think this is unnecessary in the products listing...
		// this.onMemberChange = this.onMemberChange.bind(this);
		this.onCourseChange = this.onCourseChange.bind(this);
	}

	componentDidMount() {
		const { currentStudentId } = this.props;
		const existingCart = JSON.parse(localStorage.getItem('stepup-shopping-cart-' + currentStudentId));
		const now = new Date().valueOf();
		const maxDifference = 3600000; // One hour in milliseconds

		// Compare timestamp to current time and if more than one hour has passed - ignore stored shopping cart
		if (existingCart && existingCart.items && existingCart.timestamp && ((now - existingCart.timestamp) <= maxDifference)) {

			// Filter out items that no longer exists
			const validItems = existingCart.items.filter(item => this.cartItemExists(item));
		
			this.setState({ shoppingCartItems: validItems });
		} else {
			localStorage.removeItem('stepup-shopping-cart-' + currentStudentId);
		}

	}

	cartItemExists = (cartItem) => {
		const { basicProducts } = this.props;
		const { specialProducts } = this.props;
		let existInBasicProducts = false;

		const existInSpecialProducts = specialProducts.some(item => item.id === cartItem.id)

		Object.keys(basicProducts).forEach(key => {
			if (basicProducts[key].some(item => item.id === cartItem.id)) {
				existInBasicProducts = true;
			}
		});


		return existInBasicProducts || existInSpecialProducts;

	}

	changeShoppingState = (state) => {
		this.setState({ shoppingState: state });
	}

	deleteCart = () => {
		const { currentStudentId } = this.props;
		this.setState({ shoppingCartItems: [] });
		localStorage.removeItem('stepup-shopping-cart-' + currentStudentId);
	}

	deleteCartItem = (item) => {
		const { currentStudentId } = this.props;
		const { shoppingCartItems } = this.state;

		const updatedItems = shoppingCartItems.filter((cartItem) => {
			return (cartItem.cart_index + '_' + cartItem.id) !== (item.cart_index + '_' + item.id);
		});

		this.setState({ shoppingCartItems: updatedItems });

		const shoppingCartData = {
			items: updatedItems,
			timestamp: new Date().valueOf()
		};

		if (shoppingCartData.items && shoppingCartData.items.length) {
			localStorage.setItem('stepup-shopping-cart-' + currentStudentId, JSON.stringify(shoppingCartData));
		} else {
			localStorage.removeItem('stepup-shopping-cart-' + currentStudentId);
		}
		
	}

	onAddToCart(item, event) {
		// Need to deep copy item because cart can have multiple
		// variations on same object
		const newItem = JSON.parse(JSON.stringify(item));
		const { shoppingCartItems } = this.state;
		const { shoppingCartIdCounter } = this.state;
		let newIdCounter = shoppingCartIdCounter + 1;

		const existingItems = shoppingCartItems.filter((cartItem) => {
			// Products match
			if (newItem.id === cartItem.id) {
				if (newItem.courses.length && newItem.selected_course_id !== cartItem.selected_course_id) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		});

		// Add to cart only if item is not there yet
		if (!existingItems.length) {
			// User can buy multiple variations of the same item (for example season pass for different courses)
			// so product.id is not good unique identifier anymore.
			newItem.cart_index = newIdCounter;

			// Initially one product is bought
			newItem.number_of_products = 1;

			shoppingCartItems.push(newItem);
			this.showFlashNotice(newItem.name);

			this.setState({ shoppingCartItems: shoppingCartItems, shoppingCartIdCounter: newIdCounter });
		}

		// Add to local storage!
	}

	showFlashNotice(text) {
		this.setState({ flashNotice: { visible: true, active: false, text: text } });
		this.setState({ flashNotice: { visible: true, active: true, text: text } });

		setTimeout(() => {
			this.setState({ flashNotice: { visible: true, active: false, text: text } });
			this.setState({ flashNotice: { visible: false, active: false, text: '' } });
		}, 3000)
	}

	// onMemberChange(item, event) {
	// 	console.log("Change member on: " + item.name, event.target.name, event.target.value);
	// 	const recipientId = event.target.value;

	// 	this.updateBasicProduct(item.id, { recipient_id: recipientId });
	// }

	onCourseChange(item, event) {
		const selectedCourseId = parseInt(event.target.value);
		
		this.updateBasicProduct(item, { selected_course_id: selectedCourseId });
	}

	updateBasicProduct(selectedProduct, updateData) {
		const { basicProducts } = this.state;
		const updatedBasicProducts = {};

		// TODO: make this more efficient, no need to loop them all through...
		Object.keys(basicProducts).map((key) => {
			updatedBasicProducts[key] = basicProducts[key].map((product) => {
				if (selectedProduct.id === product.id) {
					if (updateData.recipient_id) {
						product.recipient_id = updateData.recipient_id;
					}
					if (updateData.selected_course_id) {
						product.selected_course_id = updateData.selected_course_id;
					}
				}

				return product;
			});
		});

		this.setState({ basicProducts: updatedBasicProducts });
	}

	numberToCurrency(number) {
		return (Number(number).toFixed(2) + ' €').replace('.', ',');
	}

	numberToPercent(number) {
		let percent = (Number(number) + '%').replace('.', ',');

		if (percent.includes(',')) {
			percent = (Number(number).toFixed(1) + '%').replace('.', ',');
		}

		return percent;
	}

  	render () {
  		const { infoHtml } = this.props;
  		const { basicProducts } = this.state;
  		const { specialProducts } = this.state;
  		const { campsAndIntensiveProducts } = this.state;
  		const { members } = this.props;
  		const { currentStudentId } = this.props;
  		const { shoppingState } = this.state;
  		const { flashNotice } = this.state;

  		const infoBlock = this.props.infoHtml ? (
	  		<div className="page-section">
	      		<div className="content-section content-section--purple">
	      			<div className="rich-text" dangerouslySetInnerHTML={{ __html: infoHtml }}></div>
	      		</div>
	      	</div>
	  	) : null;

	  	const paymentInfoBlock = (
	  		<div className="page-section">
	      		<div className="content-section content-section--purple">
	      			<div className="rich-text">{ I18n.t('webshop.shopping_cart.info_text') }</div>
	      		</div>
	      	</div>
	  	);

	  	const shoppingCart = <ShoppingCart
	  		members={ members }
	  		shoppingCartItems={ this.state.shoppingCartItems }
	  		shoppingState={ this.state.shoppingState }
	  		deleteCartItem={ this.deleteCartItem }
	  		deleteCart={ this.deleteCart }
	  		changeShoppingState={ this.changeShoppingState }
	  		csrfToken={ this.props.csrfToken }
	  		paymentsPath={ this.props.paymentsPath }
	  		currentStudentId={ this.props.currentStudentId }
	  		validSeasonPassCount={ this.props.validSeasonPassCount }
	  	/>;

	  	const productRow = (product) => {

	  		const rowContent = (
	  			<div className="table__row" key={ product.id }>
					<div className="table__cell">{ product.name }</div>
					<div className="table__cell">{ this.numberToCurrency(product.base_price) }</div>
					<div className="table__cell">{ this.numberToPercent(product.discount_percent) }</div>
					<div className="table__cell">{ this.numberToCurrency(product.price) }</div>
					<div className="table__cell">
						{ (product.courses.length && product.type_of_ticket !== 'erikoislippu') ? (
							<select value={ product.selected_course_id } onChange={ this.onCourseChange.bind(this, product) } name="courses" id={ 'courses_' + product.id } className="form-control">
								{ product.courses.map((course) => {
									return <option key={course.id} value={course.id}>{ course.name }</option>;
								}) }
							</select>
							) :
							<span>&nbsp;</span>
						}

						{ (product.courses.length && product.type_of_ticket === 'erikoislippu') ? (
							<span>{ product.courses.map(course => course.name).join(', ') }</span>
							) : null }
					</div>
					<div className="table__cell align-right">
						<button className="btn btn-danger" onClick={this.onAddToCart.bind(this, product)}>
							{ I18n.t('webshop.products.add_to_cart') }
						</button>
					</div>
				</div>
	  		);

	  		// Don't show season passes without courses
	  		if (product.type_of_ticket === 'kausimaksu' && product.courses.length < 1) {
	  			return null;
	  		} else {
	  			return rowContent;
	  		}
	  	}

	  	const productsBlocks = Object.keys(basicProducts).map((key, index) => {
	  		return (
	  			<div className="page-section" key={ index }>
		  			<div className="content-section content-section--white">
		  				<div className="row">
		  					<div className="col-lg-12">
		  						<h4 className="sub-title">{ key }</h4>
		  					</div>
		  				</div>
		  				<div className="table">
		  					<div className="table__row table__header">
		  						<div className="table__cell">{ I18n.t('webshop.products.product') }</div>
		  						<div className="table__cell">{ I18n.t('webshop.products.base_price') }</div>
								<div className="table__cell">{ I18n.t('webshop.products.discount_percent') }</div>
		  						<div className="table__cell">{ I18n.t('webshop.products.price') }</div>
		  						<div className="table__cell">{ I18n.t('webshop.products.course') }</div>
		  						<div className="table__cell">&nbsp;</div>
		  					</div>
		  					{ basicProducts[key].map((product) => {
		  						return productRow(product);
		  					}) }
		  				</div>
		  			</div>
		  		</div>
		  	);
	  	});

	  	const specialProductsBlocks = specialProducts.length ? (
	  		<div className="page-section">
	  			<div className="content-section content-section--white">
	  				<div className="row">
		  				<div className="col-lg-12">
		  					<h4 className="sub-title">{ I18n.t('webshop.products.special_products') }</h4>
		  				</div>
		  			</div>
	  				<div className="table">
	  					<div className="table__row table__header">
	  						<div className="table__cell">{ I18n.t('webshop.products.product') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.base_price') }</div>
							<div className="table__cell">{ I18n.t('webshop.products.discount_percent') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.price') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.course') }</div>
	  						<div className="table__cell">&nbsp;</div>
	  					</div>
  						{ specialProducts.map(product => productRow(product)) }
	  				</div>	
	  			</div>
	  		</div>
	  	) : null;

	  	const campsAndIntensiveProductsBlocks = campsAndIntensiveProducts.length ? (
	  		<div className="page-section">
	  			<div className="content-section content-section--white">
	  				<div className="row">
		  				<div className="col-lg-12">
		  					<h4 className="sub-title">{ I18n.t('webshop.products.camps_and_intensive') }</h4>
		  				</div>
		  			</div>
	  				<div className="table">
	  					<div className="table__row table__header">
	  						<div className="table__cell">{ I18n.t('webshop.products.product') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.base_price') }</div>
							<div className="table__cell">{ I18n.t('webshop.products.discount_percent') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.price') }</div>
	  						<div className="table__cell">{ I18n.t('webshop.products.course') }</div>
	  						<div className="table__cell">&nbsp;</div>
	  					</div>
  						{ campsAndIntensiveProducts.map(product => productRow(product)) }
	  				</div>	
	  			</div>
	  		</div>
	  	) : null;

	  	const productTerms = <Terms />;

	  	const paymentProviderBanner = (
	  		<div className="page-section">
				<div className="content-section content-section--white">
					<img src="/assets/paytrail-banner.png" className="responsive-image" alt="Paytrail" />
				</div>
			</div>
	  	);

	  	const flashNoticeElem = (
	  		<div className={ flashNotice.visible ? (flashNotice.active ? 'notice-bar visible active' : 'notice-bar visible') : 'notice-bar' }>
	  			<div className="notice-bar__notice alert alert-secondary" role="alert">
	  				<div className="small-text bold">{ this.state.flashNotice.text }</div>
	  				<div className="small-text">{ I18n.t("webshop.shopping_cart.item_added") }</div>
	  			</div>
	  		</div>
	  	);

    	return (
      		<div className="products">
      			{ flashNoticeElem }
      			{ shoppingCart }
      			{ shoppingState === 2 ? paymentInfoBlock : null }
      			{ shoppingState === 0 ? infoBlock : null }
      			{ shoppingState === 0 ? specialProductsBlocks: null }
      			{ shoppingState === 0 ? productsBlocks : null }
      			{ shoppingState === 0 ? campsAndIntensiveProductsBlocks: null }
      			{ shoppingState === 1 ? productTerms : null }
      			{ shoppingState === 2 ? paymentProviderBanner : null }
      		</div>
    	);
  	}
}

Products.propTypes = {
	infoHtml: 					PropTypes.string,
	csrfToken: 					PropTypes.string.isRequired,
	basicProducts: 				PropTypes.object,
	specialProducts: 			PropTypes.array,
	campsAndIntensiveProducts: 	PropTypes.array,
	members: 					PropTypes.array.isRequired,
	currentStudentId: 			PropTypes.number.isRequired,
	paymentsPath: 				PropTypes.string.isRequired,
	validSeasonPassCount: 		PropTypes.number.isRequired
};

export default Products;
