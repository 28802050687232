import React, { Component } from "react"
import PropTypes from "prop-types"
class Terms extends Component {
  	render () {
    	return (
    		<React.Fragment>
    			<div className="product-terms page-section">
      				<div className="content-section content-section--white">
						<h4>KERTALIPPUJEN TUOTE­-EHDOT</h4>
						<p>Kertaliput ovat henkilökohtaisia. Lipun hinta määräytyy tunnin keston mukaan. Arkiaamun liput käyvät kuitenkin maksuksi vain arkisin ennen klo 12.00 alkavilla tunneilla ja muilla tunneilla vain lisämaksusta. Kertalipulla voi vahvistaa ilmoittautumisen yhdelle viikottaiselle tunnille koko kauden ajaksi. Kertaliput käyvät maksuvälineenä kaikilla saman hintaisilla tunneilla, sekä lisämaksusta myös kalliimmilla tunneilla. Kertaliput ovat voimassa 365 päivää ostopäivästä. Käyttämättömiä kertalippuja ei lunasteta takaisin. Kertalippujen voimassaoloaikaa voidaan pidentää ainoastaan lääkärintodistuksella. Kertalipuilla ei voi maksaa leirejä eikä tiiviskursseja.</p>

						<h4>SARJALIPPUJEN TUOTE­-EHDOT</h4>
						<p>Sarjaliput ovat henkilökohtaisia. Lipun hinta määräytyy tunnin keston ja lipussa olevien kertojen mukaan. Arkiaamun liput käyvät kuitenkin maksuksi vain arkisin ennen klo 12.00 alkavilla tunneilla ja muilla tunneilla vain lisämaksusta. Sarjalipulla voi vahvistaa ilmoittautumisen yhtä monelle viikottaiselle tunnille koko kauden ajaksi, kuin sarjalipussa on käyttämättömiä kertoja. Sarjaliput käyvät maksuvälineenä kaikilla saman hintaisilla tunneilla, sekä lisämaksusta myös kalliimmilla tunneilla. Sarjaliput ovat voimassa kuusi kuukautta käyttöönottopäivästä lähtien, kuitenkin enintään 365 päivää ostopäivästä. Sarjalippujen voimassaoloajoissa on valmiiksi huomioitu ne päivät, jolloin tanssikoulu on suljettu. Käyttämättömiä sarjalippuja ei lunasteta takaisin. Sarjalippujen voimassaoloaikaa voidaan pidentää ainoastaan lääkärintodistuksella. Sarjalipuilla ei voi maksaa leirejä eikä tiiviskursseja.</p>

						<h4>KAUSIMAKSUJEN TUOTE­-EHDOT</h4>
						<p>Kausimaksut ovat henkilökohtaisia. Lipun hinta määräytyy tunnin keston ja ostohetken mukaan. Arkiaamun liput käyvät kuitenkin maksuksi vain arkisin ennen klo 12.00 alkavilla tunneilla ja muilla tunneilla vain lisämaksusta. Kausimaksu on myynnissä kauden viisi ensimmäistä viikkoa ja sen hinta alenee viikoittain puolikkaan kertalipun verran. Kausimaksulla voi vahvistaa ilmoittautumisen yhdelle viikottaiselle tunnille koko kaudeksi ja se kattaa kyseisen tunnin kauden loppuun asti. Kausimaksua ei voi siirtää tunnilta toiselle. Kausimaksun ostamalla oppilas sitoutuu käymään kyseisellä tunnilla kauden loppuun asti. Kausimaksun maksaneet oppilaat voivat korvata kauden aikana korkeintaan viisi poissaoloa/kausimaksu toisella saman hintaisella tai edullisemmalla tunnilla. Poissaolo on mahdollista korvata myös kalliimmalla tunnilla maksamalla tuntien kertamaksujen välinen erotus. Poissaolot on korvattava ennen kyseisen kauden päättymistä. Viisi korvauskertaa ovat asiakkaan vapaasti käytettävissä itse valittavana ajankohtana ko. kauden aikana eikä niistä tarvitse ilmoittaa etukäteen toimistolle. Useamman kuin viiden poissaolokerran korvaamista varten on toimitettava lääkärintodistus. Käyttämättömiä kausimaksuja ei lunasteta takaisin eikä kausimaksusta käyttämättä jääneitä kertoja hyvitetä takaisin. Usean kausimaksun ostavalle oppilaalle myönnämme henkilökohtaisen kausimaksualennuksen siten, että kauden ensimmäinen kausimaksu on täysihintainen ja muista kausimaksuista myönnämme 15 %:n alennuksen riippumatta siitä milloin kausimaksut ostetaan. Kausimaksulla ei voi maksaa leirejä eikä tiiiviskursseja.</p>

						<h4>ANYTIME­KORTTIEN TUOTE­-EHDOT</h4>
						<p>Anytime-kortit ovat henkilökohtaisia. Anytime-korteilla voi vahvistaa ilmoittautumisen kaikille haluamilleen tunneille. Anytime-korteilla voi käydä kaikilla avoimilla viikottaisilla tunneilla. Koko kauden Anytime-kortit ovat voimassa kyseisen kauden loppuun saakka. Viikon ja kuukauden Anytime-kortit ovat voimassa 7 päivää tai 30 päivää käyttöönottopäivästä alkaen. Kuitenkin enintään 365 päivää ostopäivästä. Viikon ja kuukauden Anytime-korttien voimassaoloajoissa on valmiiksi huomioitu ne päivät, jolloin tanssikoulu on suljettu. Viikon ja kuukauden Anytime-korttien voimassaoloaikaa voidaan pidentää ainoastaan lääkärintodistuksella. Kauden Anytime-korteista voidaan hyvittää lääkärintodistuksella vähintään 10 vuorokauden yhtäjaksoinen poissaolo. Käyttämättömiä Anytime-kortteja ei lunasteta takaisin. Anytime-korteilla ei voi maksaa leirejä eikä tiiviskursseja.</p>

						<h4>LEIRIMAKSUJEN TUOTE-EHDOT</h4>
						<p>Leirimaksut ovat henkilökohtaisia. Leirimaksulla voi vahvistaa ilmoittautumisen valitulle leirille. Leiri-ilmoittautumisen voi peruuttaa kirjallisesti sähköpostilla osoitteeseen school@stepup.fi. Leirimaksu palautetaan kokonaisuudessaan, mikäli peruutus tehdään viimeistään kaksi viikkoa ennen leirin alkua. Jos peruutus tehdään, kun leirin alkuun on alle kaksi viikkoa, mutta yli viikko, perimme peruutuksesta toimisto-, ruoka- ja muita kuluja 25 €. Jos peruutus tehdään, kun leirin alkuun on alle viikko aikaa perimme kuluja 65 €. Peruuttamatta jätetystä leiristä ei palauteta leirimaksua. Mikäli peruutus johtuu äkillisestä sairastumisesta, palautetaan leirimaksu lääkärintodistusta vastaan. Asiakas on vastuussa mahdollisten ruoka-allergioidensa ilmoittamisesta tanssikoululle. Leiri voidaan peruuttaa järjestäjän toimesta, mikäli leirille ei ilmoittaudu tarvittavaa määrää oppilaita, tai muusta vastaavanlaisesta syystä. Leirin peruuntumisesta ilmoitetaan viimeistään viikkoa ennen leirin alkua. Leirimaksut palautetaan leirin peruuntuessa järjestäjän toimesta. Leirimaksuilla ei voi maksaa viikkotunteja.</p>
						
						<h4>TIIVISKURSSIMAKSUJEN TUOTE-EHDOT</h4>
						<p>Tiiviskurssimaksut ovat henkilökohtaisia. Tiiviskurssimaksulla voi vahvistaa ilmoittautumisen valitulle tiiviskurssille. Tiiviskurssi-ilmoittautumisen voi peruuttaa kirjallisesti sähköpostilla osoitteeseen school@stepup.fi. Tiiviskurssimaksu palautetaan kokonaisuudessaan, mikäli peruutus tehdään viimeistään kahta viikkoa ennen kurssin alkua. Jos peruutus tehdään, kun kurssin alkuun on alle kaksi viikkoa, mutta yli yksi viikko, palautamme 50% kurssin hinnasta. Jos peruutus tehdään tämän jälkeen, palautetaan tiiviskurssimaksu vain lääkärintodistusta vastaan. Peruuttamatta jätetystä tiiviskurssista ei palauteta tiiviskurssimaksua. Tiiviskurssi voidaan peruuttaa järjestäjän toimesta, mikäli kurssille ei ilmoittaudu tarvittavaa määrää oppilaita, tai muusta vastaavanlaisesta syystä. Kurssin peruuntumisesta ilmoitetaan viimeistään viisi päivää ennen tiiviskurssin alkua. Tiiviskurssimaksut palautetaan tiiviskurssin peruuntuessa järjestäjän toimesta. Tiiviskurssimaksuilla ei voi maksaa viikkotunteja.</p>
					</div>
				</div>
      		</React.Fragment>
    	);
  	}
}

export default Terms;
