import React, { Component } from "react"
import PropTypes from "prop-types"

class SubNavigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mobileMenuVisible: false
		};
	};

	getCurrentTitle() {
		const { currentPath } = this.props;
		const { items } = this.props;
		let title = '';

		for (let i = 0; i < items.length; i++) {
			const itemPath = items[i].path.split('?')[0];
			if (itemPath === currentPath) {
				title = items[i].title;
				break;
			}
		}

		return title;
	}

	onMobileMenuToggle(event) {
		this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible });
	}

  	render () {
  		const { items } = this.props;
  		const { mobileMenuVisible } = this.state;
  		const { currentPath } = this.props;
  		const { authenticated } = this.props;

  		const menuItems = items.map((item, index) => {
  			
  			return (!item.requires_authentication || authenticated) ? (
  				<div className={ item.path.split('?')[0] === currentPath ? 'sub-navigation__item active' : 'sub-navigation__item' } key={index}>
  					<a href={ item.path } title={ item.title }>{ item.title }</a>
  				</div>
  			) : null;
  		});

	    return (
	      	<div className="sub-navigation">
	      		<div className="sub-navigation__mobile-toggle" onClick={ this.onMobileMenuToggle.bind(this) }>
      				<span>{ "Valitse toiminto" }</span>
      				<i className="fas fa-chevron-down"></i>
	      		</div>
	      		<div className={ mobileMenuVisible ? 'sub-navigation__content container visible' : 'sub-navigation__content container' }>
	      			<div className="sub-navigation__menu">
	      				{ menuItems }
	      			</div>
	      		</div>
	      	</div>
	    );
  }
}

SubNavigation.propTypes = {
	currentPath: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	authenticated: PropTypes.bool.isRequired
};

export default SubNavigation;
