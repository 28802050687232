import React from "react"
import PropTypes from "prop-types"

class Accordion extends React.Component {
	state = {
		contentVisible: false
	};

	render () {
	  	const { bodyText, buttonText } = this.props;
	  	const content = (
	  		<div className={ this.state.contentVisible ? 'expander__body expander__body--open' : 'expander__body' }>
      			<span dangerouslySetInnerHTML={{ __html: bodyText }} />
      		</div>
	  	);

	    return (
	      <div className="expander expander--table-cell">
	      	<div className="expander__toggle" onClick={ () => this.setState({ contentVisible: !this.state.contentVisible }) }>
	      		<span>{ buttonText }</span>
	      		<i className="fas fa-chevron-down"></i>
	      	</div>
	      	{ content }
	      </div>
	    );
	}
}

Accordion.propTypes = {
	buttonText: PropTypes.string.isRequired,
	bodyText: PropTypes.string.isRequired
};

export default Accordion;
