import React, { Component } from "react"
import PropTypes from "prop-types"

class ShoppingCart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shoppingCartItems: this.props.shoppingCartItems,
			shoppingState: this.props.shoppingState,
			productTermsAccepted: false,
			seasonPassDiscount: 0
		};

		this.onMemberChange = this.onMemberChange.bind(this);
		this.onNumberOfItemsChange = this.onNumberOfItemsChange.bind(this);
		this.onCourseChange = this.onCourseChange.bind(this);
		this.onChangeItemsAmount = this.onChangeItemsAmount.bind(this);
		this.onItemDelete = this.onItemDelete.bind(this);
		this.ondeleteCart = this.ondeleteCart.bind(this);
		this.onForwardClick = this.onForwardClick.bind(this);
		this.onBackToShop = this.onBackToShop.bind(this);
		this.onTermsChange = this.onTermsChange.bind(this);
		this.onCartSubmit = this.onCartSubmit.bind(this);
		this.onChangeShoppingState = this.onChangeShoppingState.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const seasonPassObj = this.calculateSeasonPassDiscount(nextProps.shoppingCartItems);

  		this.setState({
  			shoppingCartItems: nextProps.shoppingCartItems,
  			shoppingState: nextProps.shoppingState,
  			seasonPassDiscount: seasonPassObj.seasonPassDiscount
  		});
	}

	calculateSeasonPassDiscount(items) {
		let { validSeasonPassCount } = this.props;
		const seasonPasses = items.filter(item => item.type_of_ticket === 'kausimaksu');
		let prices = [];
		let sum = 0;

		for (let i = 0; i < seasonPasses.length; i++) {
			const item = seasonPasses[i];
			const discountedPrice = item.prices['student_' + item.recipient_id]
			const seasonPassDiscountedPrice = discountedPrice * 0.15;
			const price = seasonPassDiscountedPrice;
			let amount = item.number_of_products;

			if (amount > 0 && amount < 2) {
				prices.push(price);
			} else {
				for (let j = 0; j < amount; j++) {
					prices.push(price);
				}
			}

		}

		prices = prices.sort((a, b) => a - b);

		if (validSeasonPassCount > 0 && seasonPasses.length) {
			sum = prices.reduce((total, value) => { return total + value}, 0);
		} else if (seasonPasses.length > 0) {
			prices.pop();
			sum = prices.reduce((total, value) => { return total + value}, 0);
		}

		const seasonPassDiscountObj = { seasonPassDiscount: sum };

		return seasonPassDiscountObj;
	} 

	onTermsChange() {
		const { productTermsAccepted } = this.state;
		this.setState({ productTermsAccepted: !productTermsAccepted });
	}

	// Store cart to local storage
	onCartSubmit(event) {
		const { currentStudentId } = this.props;
		const { shoppingCartItems } = this.state;
		const shoppingCartData = {
			items: shoppingCartItems,
			timestamp: new Date().valueOf()
		};

		localStorage.setItem('stepup-shopping-cart-' + currentStudentId, JSON.stringify(shoppingCartData));
	}

	onChangeShoppingState(newState) {
		this.props.changeShoppingState(newState);
	}

	// Forward to product terms...
	onForwardClick() {
		this.props.changeShoppingState(1);
	}

	onBackToShop() {
		this.props.changeShoppingState(0);
	}

	onItemDelete(item, event) {
		this.props.deleteCartItem(item);
	}

	ondeleteCart(event) {
		this.props.deleteCart();
	}

	// Change the input field
	onNumberOfItemsChange(item, event) {
		let numberOfItems = parseInt(event.target.value);

		if (isNaN(numberOfItems) || !numberOfItems || !Number.isInteger(numberOfItems)) {
			numberOfItems = 0;
		}

		this.updateItem(item, { number_of_products: numberOfItems });
	}

	// Use buttons to change input field value
	onChangeItemsAmount(obj, event) {
		if (obj.item && obj.amount) {
			let numberOfItems = obj.item.number_of_products + obj.amount;

			if (!numberOfItems || isNaN(numberOfItems) || numberOfItems < 0) {
				numberOfItems = 0;
			}

			this.updateItem(obj.item, { number_of_products: numberOfItems });
		}
	}

	onMemberChange(item, event) {
		const recipientId = parseInt(event.target.value);
		
		this.updateItem(item, { recipient_id: recipientId });
	}

	onCourseChange(item, event) {
		const selectedCourseId = parseInt(event.target.value);
		
		this.updateItem(item, { selected_course_id: selectedCourseId });
	}

	getRecipientName(product) {
		let recipient = this.props.members.find((member) => {
			return member.id === product.recipient_id
		});

		return (recipient && recipient.name) ? recipient.name : "-";
	}

	updateItem(item, updateData) {
		const { shoppingCartItems } = this.state;

		const updatedItems = shoppingCartItems.map((cartItem) => {
			if (cartItem.id === item.id && cartItem.cart_index === item.cart_index) {
				if (updateData.recipient_id) {
					cartItem.recipient_id = updateData.recipient_id;
				}

				if (updateData.selected_course_id) {
					cartItem.selected_course_id = updateData.selected_course_id;
				}

				if (updateData.number_of_products === 0 || updateData.number_of_products) {
					cartItem.number_of_products = updateData.number_of_products;
				}
			}

			return cartItem;
		});

		const seasonPassObj = this.calculateSeasonPassDiscount(updatedItems);

		this.setState({ shoppingCartItems: updatedItems, seasonPassDiscount: seasonPassObj.seasonPassDiscount, validSeasonPassCount: seasonPassObj.validSeasonPassCount });
	}

	numberToCurrency(number) {
		return (Number(number).toFixed(2) + ' €').replace('.', ',');
	}

	totalSum(items) {
		const { seasonPassDiscount } = this.state;
		const itemTotals = items.map((item) => {

			const price = item.prices['student_' + item.recipient_id];
			
  			return item.number_of_products * price;
  		});

		const sum = itemTotals.reduce((total, value) => {
  			return total + value;
  		}, 0);

  		return sum - seasonPassDiscount; 
	}

  	render () {
  		const { shoppingCartItems } = this.state;
  		const { members } = this.props;
  		const { shoppingState } = this.state;
  		const { productTermsAccepted } = this.state;
  		const { seasonPassDiscount } = this.state;
  		const { csrfToken } = this.props;

  		const noItems = <span className="basic-text">{ I18n.t('webshop.shopping_cart.no_items') }</span>;

  		const cartTableHeader = (
  			<div className="table__row table__header">
  				<div className="table__cell">{ I18n.t('webshop.products.product') }</div>
  				<div className="table__cell">{ I18n.t('webshop.products.course') }</div>
  				<div className="table__cell">{ I18n.t('webshop.products.price') }</div>
  				<div className="table__cell">{ I18n.t('webshop.products.receiver') }</div>
  				<div className="table__cell">{ I18n.t('webshop.products.pcs') }</div>
  				<div className="table__cell">&nbsp;</div>
  			</div>
  		);

  		const memberSelect = (product) => {
  			return shoppingState !== 2 && product.type_of_ticket !== 'erikoislippu' && !product.special_discounted ? (
  				<select value={ product.recipient_id } readOnly={ shoppingState === 2 } onChange={ this.onMemberChange.bind(this, product) } name="cart_items[][recipient_id]" id={ 'cart_item_recipient_' + product.id } className="form-control">
					{ members.map((member) => {
						return <option key={member.id} value={member.id}>{ member.name }</option>;
					}) }
				</select>
			) : (
				<div>
					<span>{ this.getRecipientName(product) }</span>
					<input value={product.recipient_id} type="hidden" name="cart_items[][recipient_id]" />
				</div>
			);
  		};

  		const courseDropdown = (product) => {
  			return product.type_of_ticket !== 'erikoislippu' ? (
  				<select value={ product.selected_course_id } onChange={ this.onCourseChange.bind(this, product) } readOnly={ shoppingState !== 1 } name="cart_items[][course_id]" id={ 'cart_item_course_' + product.id } className="form-control">
					{ product.courses.map((course) => {
						return <option key={course.id} value={course.id}>{ course.name }</option>;
					}) }
				</select>
  			) : <span>{ product.courses.map(course => course.name).join(', ') }</span>;
  		};

  		const numberOfItems = (product) => {
  			return (
  				<div className="number-of-items">
  					{ shoppingState !== 2 ? 
  					<button type="button" className="btn btn-light" onClick={ this.onChangeItemsAmount.bind(this, { item: product, amount: -1 }) } >
  						<i className="fas fa-minus"></i>
  					</button>
  					: null }
  					<input className="form-control" readOnly={ shoppingState === 2 } id={ 'number_of_items_' + product.cart_index } type="number" name="cart_items[][number_of_items]" min="0" value={ product.number_of_products } onChange={ this.onNumberOfItemsChange.bind(this, product) } />
  					{ shoppingState !== 2 ?
  					<button type="button" className="btn btn-light" onClick={ this.onChangeItemsAmount.bind(this, { item: product, amount: 1 }) }>
  						<i className="fas fa-plus"></i>
  					</button>
  					: null }
  				</div>
  			);
  		};

  		const deleteItem = (product) => {
  			return (
  				<div className="remove-item">
  					<button className="btn btn-danger" onClick={ this.onItemDelete.bind(this, product)}>
  						<i className="fas fa-trash-alt"></i>
  					</button>
  				</div>
  			);
  		};

  		const cartItems = shoppingCartItems.map((item) => {
  			const courseElem = item.courses.length ? courseDropdown(item) : null;
  			const unitPriceForMember = item.prices['student_' + item.recipient_id];
  			const totalPrice = this.numberToCurrency(item.number_of_products * unitPriceForMember);

  			return (
  				<div className="table__row" key={ item.cart_index + '_' + item.id }>
  					<input type="hidden" name="cart_items[][product_id]" value={ item.id} />
  					<div className="table__cell">
  						<span>{ item.name }</span>
  						<br />
  						<span>{ this.numberToCurrency(unitPriceForMember) + ' / ' + I18n.t('webshop.products.pcs').toLowerCase() }</span>
  					</div>
  					<div className="table__cell">{ courseElem }</div>
  					<div className="table__cell">{ totalPrice }</div>
  					<div className="table__cell">{ memberSelect(item) }</div>
  					<div className="table__cell">{ numberOfItems(item) }</div>
  					<div className="table__cell align-right">{ shoppingState === 2 ? null : deleteItem(item) }</div>
  				</div>
  			);
  		});

  		const totalsRow =  (
  			<div className="totals-row align-right">
  				<div className="totals-row__total">
					<span>{ I18n.t('webshop.shopping_cart.total') }:</span>
					<span>&nbsp;</span>
					<span>{ this.numberToCurrency(this.totalSum(shoppingCartItems)) }</span>
				</div>
			</div>
		);

  		const termsConsent = (
  			<div className="align-right">
	  			<div className="form-check form-check-inline">
		  			<input className="form-check-input" type="checkbox" value={ productTermsAccepted } checked={ productTermsAccepted } name="terms" onChange={ this.onTermsChange.bind(this) } />
		  			<span className="form-check-label">{ I18n.t('webshop.shopping_cart.terms_agreed') }</span>
		  		</div>
		  	</div>
  		);

  		const shoppingCartActions = (
  			<div className="action-buttons">
  				{ shoppingCartItems.length ? <button className="btn" onClick={ this.ondeleteCart.bind(this) } >{ I18n.t('webshop.shopping_cart.cancel') }</button>  : null }
  				{ shoppingState === 0 ? <button className="btn btn-danger" onClick={ this.onChangeShoppingState.bind(this, 1) }>{ I18n.t('webshop.shopping_cart.forward') }</button> : null }
  				{ shoppingState === 1 ? <button className="btn btn-secondary" onClick={ this.onChangeShoppingState.bind(this, 0) }>{ I18n.t('webshop.shopping_cart.back_to_shop') }</button> : null }
  				{ shoppingState === 1 ? <button className="btn btn-danger" onClick={ this.onChangeShoppingState.bind(this, 2) } disabled={ !productTermsAccepted }>{ I18n.t('webshop.shopping_cart.forward') }</button> : null }
  				{ shoppingState === 2 ? <button className="btn btn-secondary" onClick={ this.onChangeShoppingState.bind(this, 1) }>{ I18n.t('webshop.shopping_cart.back_to_shop') }</button> : null }
  				{ shoppingState === 2 ? <input type="submit" className="btn btn-danger" onClick={ this.onCartSubmit.bind(this) } disabled={ !productTermsAccepted } value={ I18n.t('webshop.shopping_cart.to_payment') } /> : null }
  			</div>
  		);

	  	const progressBar = (
	  		<div className="progress">
	  			<div className="progress-bar" role="progressbar" style={{ width: (100/4 * (shoppingState + 1)) + '%' }} aria-valuenow={ 100/4 * (shoppingState + 1)} aria-valuemin="0" aria-valuemax="100">
	  				{ I18n.t('webshop.shopping_cart.stepper.step_' + shoppingState) }
	  			</div>
	  		</div>
	  	);

	  	const seasonPassDiscountRow = (
	  		<div className="totals-row totals-row--sale align-right">
	  			<div className="totals-row__total">
		  			<span>{ I18n.t('webshop.shopping_cart.season_pass_discount') }:</span>
		  			<span>&nbsp;</span>
		  			<span>{ this.numberToCurrency(seasonPassDiscount) }</span>
		  		</div>
	  		</div>
	  	);

	  	return (
	    	<React.Fragment>
	    		<div className="page-section">
	  				<div className="content-section content-section--white">
	  					<div className="row">
	  						<div className="col-lg-12">
	  							<h4 className="sub-title">{ I18n.t('webshop.products.shopping_cart') }</h4>
	  						</div>
	  					</div>
	  					{ shoppingCartItems.length ? 
		  					<div className="row">
		  						<div className="col-lg-12">
		  							{ progressBar }
		  							<p>&nbsp;</p>
		  						</div>
		  					</div>
		  				: null }

	  					<form action={ this.props.paymentsPath } method="post">
	  						<input type="hidden" name="authenticity_token" value={ csrfToken } />
		  					<div className="table">
		  						{ shoppingCartItems.length ? cartTableHeader : null }
		  						{ shoppingCartItems.length ? cartItems : noItems }
		  					</div>

		  					<div className="row">
		  						<div className="col-lg-12">
		  							{ seasonPassDiscount && seasonPassDiscount > 0 ? seasonPassDiscountRow : null }
		  						</div>
		  					</div>

		  					<div className="row">
		  						<div className="col-lg-12">{ shoppingState === 2 ? totalsRow : null }</div>
		  					</div>

		  					<div className="row">
		  						<div className="col-lg-12">{ shoppingState === 1 ? termsConsent : null }</div>
		  					</div>
		  					<div className="row">
		  						<div className="col-lg-12">{ shoppingCartItems.length ? shoppingCartActions : null }</div>
		  					</div>
		  				</form>
	  				</div>
	  			</div>
	    	</React.Fragment>
	    );
  	}
}

ShoppingCart.propTypes = {
	csrfToken: 				PropTypes.string.isRequired,
	shoppingCartItems: 		PropTypes.array.isRequired,
	members: 				PropTypes.array.isRequired,
	deleteCartItem: 		PropTypes.func.isRequired,
	deleteCart: 			PropTypes.func.isRequired,
	changeShoppingState: 	PropTypes.func.isRequired,
	shoppingState: 			PropTypes.number.isRequired,
	paymentsPath: 			PropTypes.string.isRequired,
	currentStudentId: 		PropTypes.number.isRequired,
	validSeasonPassCount: 	PropTypes.number.isRequired
};

export default ShoppingCart;
