import React, { Component } from 'react'

class WaitlistEnrollments extends Component {

  render () {

    const title = this.props.title
    const waitlistEntollmentItems = this.props.waitlist_enrollments.map( (item,index) => {
      return(
        <tr key = { index } >
          <td>{ item.id }</td>
          <td>{ item.season_name }</td>
          <td>{ item.location_name }</td>
          <td>{ item.abbreviated_weekday }</td>
          <td>{ item.time_range }</td>
          <td>{ item.course_full_name }</td>
          <td>{ item.teachers_names }</td>
          <td>{ item.room_name }</td>
          <td>{ item.more_description }</td>
          <td>{ item.current_queue }</td>
        </tr>
      )
    })


   return (
      <table id = { title } className = 'table table-striped table-hover table-condensed table-responsive' >
        <tbody>
          { waitlistEntollmentItems }
        </tbody>
      </table>
   ) 
  }

}

export default WaitlistEnrollments
