const viteConstructorRequireContext = function(reqCtx) {
    const fromRequireContext = function(reqCtx) {
      return function(className) {
        var parts = className.split(".");
        var filename = parts.shift();
        var keys = parts;
        // Load the module:
        var componentPath = Object.keys(reqCtx).find((path => path.search(filename) > 0));
        var component = reqCtx[componentPath];
        // Then access each key:
        keys.forEach(function(k) {
          component = component[k];
        });
        component = component.default;
        return component;
      }
    }
  
    const fromCtx = fromRequireContext(reqCtx);
    return function(className) {
      var component;
      try {
        // `require` will raise an error if this className isn't found:
        component = fromCtx(className);
      } catch (firstErr) {
        console.error(firstErr);
      }
      return component;
    }
  }

import ReactRailsUJS from "react_ujs";

const componentsRequireContext = import.meta.glob("~/components/**/*.{js,jsx}", { eager: true });
ReactRailsUJS.getConstructor = viteConstructorRequireContext(componentsRequireContext);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
