import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from 'lodash';
import Accordion from '../Accordion';

class Schedule extends Component {

	constructor(props) {
		super(props);

		this.state = {
			mobileFiltersVisible: false,
			filters: {
				season: '',
				venue: '',
				weekday: '',
				variety: '',
				age_range: '',
				difficulty: '',
				teachers: ''
			},
			weeklyCourses: null,
			camps: this.props.camps,
			intensiveCourses: this.props.intensiveCourses,
			// training: this.props.training,
			allergies: {},
			allergies_descriptions: {}
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onFiltersReset = this.onFiltersReset.bind(this);
		this.onAllergiesChange = this.onAllergiesChange.bind(this);
		this.onAllergiesDescriptionChange = this.onAllergiesDescriptionChange.bind(this);
	}

	componentDidMount(){
		const { seasons } = this.props;
		const { filters } = this.state;

		if (filters && seasons && seasons.length && seasons.length === 1) {
			filters.season = seasons[0];
			this.setState({ filters: filters });
	    	this.filterCourses(filters);
		}
	}

	onFiltersToggle(event) {
		this.setState({ mobileFiltersVisible: !this.state.mobileFiltersVisible });
	}

	onFilterChange(event, filterKey) {
		const value = event.target.value;
		const { filters } = this.state;

		if (value && value.length) {
			filters[filterKey] = value;
		} else {
			filters[filterKey] = '';
		}

		this.setState({ filters });
		this.filterCourses(filters);
	}

	onFiltersReset(event) {
		const emptyFilters = {
			season: '',
			venue: '',
			weekday: '',
			variety: '',
			age_range: '',
			difficulty: '',
			teachers: ''
		};

		this.setState({ filters: emptyFilters });
		this.filterCourses(emptyFilters);
	}

	filterCourses(filters) {
		const { weeklyCourses } = this.props;
		const filterKeys = Object.keys(filters);
		const filtered = {};

		Object.keys(weeklyCourses).map((key) => {
			const courses = weeklyCourses[key];

			const filteredSet = courses.filter((course) => {
				let including = true;
				filterKeys.forEach((filterKey) => {
					if (filters[filterKey] && filters[filterKey].length) {
						// course prop can be array of strings or a string
						if (Array.isArray(course[filterKey])) {
							if (!course[filterKey].includes(filters[filterKey]))
								including = false;
						} else {
							if (filters[filterKey] !== course[filterKey])
								including = false;
						}
					}
					
				});

				return including;
			});

			if (filteredSet && filteredSet.length) {
				filtered[key] = filteredSet;
			}
		});

		this.setState({ weeklyCourses: filtered });
	}

	onAllergiesChange(event, courseId) {
		const value = event.target.value;
		const { allergies, allergies_descriptions } = this.state;

		allergies[courseId] = value;

		if(value === "false"){
			allergies_descriptions[courseId] = ""
		}

		this.setState({ allergies, allergies_descriptions });
	}

	onAllergiesDescriptionChange(event, courseId) {
		const value = event.target.value;
		const { allergies_descriptions } = this.state;

		allergies_descriptions[courseId] = value;

		this.setState({ allergies_descriptions });
	}

  	render () {
  		const { weeklyCourses } = this.state;
  		const { camps } = this.state; // NO filtering ATM!
  		const { intensiveCourses } = this.state; // NO filtering ATM!
  		// const { training } = this.state; // NO filtering ATM!

  		const { seasons } = this.props;
  		const { filterCriteria } = this.props;
  		const { infoHtml } = this.props;
  		const { mobileFiltersVisible } = this.state;
  		const { allergies, allergies_descriptions } = this.state;

	  	const seasonFilters = seasons ? (seasons.map((season, index) => {
	  		return (
	  			<div className="filters__filter form-check form-check-inline" key={index}>
	  				<input className="form-check-input" type="radio" name="season" value={season} checked={ this.state.filters.season === season } onChange={ (event) => this.onFilterChange(event, 'season') } />
	  				<span className="form-check-label">{ season }</span>
	  			</div>
	  		);
	  	})) : null;

	  	const otherFilters = filterCriteria ? (filterCriteria.map((item, index) => {
	  		return (
	  			<div className="filters__filter" key={index}>
	  				<select className="form-control" value={ this.state.filters[item.type] } onChange={ (event) => this.onFilterChange(event, item.type) } disabled={ !weeklyCourses || weeklyCourses.length < 1 } >
	  					<option value=''>{ item.title }</option>
	  					{ item.options.map((option, index) => {
	  						return (
	  							<option value={ option } key={ index }>{ option }</option>
	  						);
	  					}) }
	  				</select>
	  			</div>
	  		);
	  	})) : null;

	  	const seasonFiltersRow = (seasons && seasons.length > 1) ? (
	  		<div className="filters__row" >
				<span>{ I18n.t('webshop.schedule.choose_season') }:</span>
				<span>&nbsp;</span>
				{ seasonFilters }
			</div>
	  	) : null;

	  	const filterBlock = this.props.filterCriteria ? (
	  		<div className="page-section">
		  		<div className="content-section content-section--white relative">
		  			<h3 className="mobile-button mobile-button--fill-parent" onClick={this.onFiltersToggle.bind(this)}>
		  				{ I18n.t('webshop.schedule.filters_title') }
		  				<div className="mobile-button__toggle">
		  					<i className="fas fa-sliders-h"></i>
		  				</div>
		  			</h3>

		  			<div className={ mobileFiltersVisible ? 'filters visible' : 'filters' }>
		  					{ seasonFiltersRow }
		  				<div className="filters__row">
		  					{ otherFilters }
		  				</div>
		  				<div className="filters__row">
			  				<div className="filters__filter">
			  					<button className="btn btn-danger" onClick={this.onFiltersReset.bind(this)}>{ I18n.t('webshop.schedule.reset_filters') }</button>
			  				</div>
			  			</div>
		  			</div>
		  		</div>
		  	</div>
	  	) : null;

	  	const infoBlock = this.props.infoHtml ? (
	  		<div className="page-section">
	      		<div className="content-section content-section--purple">
	      			<div className="rich-text" dangerouslySetInnerHTML={{ __html: infoHtml }}></div>
	      		</div>
	      	</div>
	  	) : null;

	  	const courseTable = (courses) => {
	  		const headerItemsArray = weeklyCourses ? 
	  			['time', 'dance_class', 'teacher', 'room', 'vacancy', 'duration', 'additional_information'] :
	  			['dance_class', 'teacher', 'vacancy', 'additional_information'];

	  		const headerItems = headerItemsArray.map((item, index) => {
	  			return (
	  				<div className="table__cell" key={ index } >
	  					{ I18n.t('webshop.courses.' + item) }
	  				</div>
	  			);
	  		});

	  		const courseRow = (course, index) => {
	  			const vacancy = (is_full) => {
	  				if (is_full) {
	  					return <span className="red">{ I18n.t('webshop.courses.is_full') }</span>
	  				} else {
	  					return <span className="green">{ I18n.t('webshop.courses.not_full') }</span>
	  				}
	  			};

	  			const additionalInformation = (bodyText) => {
					if (bodyText && bodyText.length) {
						return <Accordion bodyText={ bodyText } buttonText={ I18n.t('webshop.courses.additional_information') } />;
					} else {
						return null;
					}
				};

				const teachers = () => {
					if (course.teachers.length > 1) {
						return <Accordion bodyText={ course.teachers.join(', ') } buttonText={ I18n.t('webshop.courses.multiple_teachers') } />;
					} else {
						return <span>{ course.teachers.join(', ') }</span>
					}
				};

				const actions = () => {
					const { csrfToken } = this.props;
					if (course.is_locked || course.type === 'training') {
						return null;
					} else if (course.enrolled) {
						return <span className="highlight-purple">{ I18n.t('webshop.enrolled_to_course') }</span>;
					} else if(course.waitlist_enrolled) {
						return <span className="highlight-gray">{ I18n.t('webshop.waitlist_enrolled_to_course') }</span>;
					} else if (course.is_full) {
						return (
							<form role='form' acceptCharset="UTF-8" action='/waitlist_enroll_student' method='post'>
								<input type='hidden' name='authenticity_token' value={ csrfToken } />
								<input type='hidden' name='course_id' value={ course.id } />
								<input type='hidden' name='allergies' value={ allergies[course.id] } />
								<input type='hidden' name='allergies_description' value={ allergies_descriptions[course.id] } />
								<input type='submit' value={ I18n.t('webshop.enroll_to_waitlist') } className="btn btn-hollow" />
							</form>
						);
					} else {
						return (
							<form role='form' acceptCharset="UTF-8" action='/enroll_student' method='post'>
								<input type='hidden' name='authenticity_token' value={ csrfToken } />
								<input type='hidden' name='locale' value={ I18n.locale } />
								<input type='hidden' name='course_id' value={ course.id } />
								<input type='hidden' name='allergies' value={ allergies[course.id] } />
								<input type='hidden' name='allergies_description' value={ allergies_descriptions[course.id] } />
								<input type='submit' value={ I18n.t('webshop.enroll_to_course') } className="btn btn-hollow" />
							</form>
						);
					}
				};

	  			return (
	  				<div className="table__row" key={index}>
	  					{ weeklyCourses ? <div className="table__cell table__cell--nowrap bold">{ course.time }</div> : null }
	  					<div className="table__cell bold">
	  						<div>{ course.name }</div>
								
								{ course.type === 'camp' && (
		  						<div className='row'>
		  							<div className="col-6">
			  							<input type="radio" id={`no_allergies_radio_${course.id}`} value="false" checked={allergies[course.id] === "false"} onChange={ (event) => this.onAllergiesChange(event, course.id) } />
			  							<label htmlFor={`no_allergies_radio_${course.id}`} className="ml-1">{I18n.t("enrollments.no_allergies")}</label>
			  						</div>
			  						<div className="col-6">
			  							<input type="radio" id={`has_allergies_radio_${course.id}`} value="true" checked={allergies[course.id] === "true"} onChange={ (event) => this.onAllergiesChange(event, course.id) } />
			  							<label htmlFor={`has_allergies_radio_${course.id}`} className="ml-1">{I18n.t("enrollments.has_allergies")}</label>
			  						</div>
			  						<div className="col-12">
		  								<input 	type="text"
		  												name={`allergies_description[${course.id}]`} 
		  												className="form-control"
		  												placeholder={I18n.t("enrollments.allergies_description")}
		  												value={allergies_descriptions[course.id]}
		  												onChange={ (event) => this.onAllergiesDescriptionChange(event, course.id) } />
		  							</div>
		  						</div>
		  					)}

	  					</div>
	  					<div className="table__cell">{ teachers() }</div>
	  					{ weeklyCourses ? <div className="table__cell table__cell--nowrap">{ course.room }</div> : null }
	  					<div className="table__cell">{ vacancy(course.is_full) }</div>
	  					{ weeklyCourses ? <div className="table__cell table__cell--nowrap">{ I18n.t('webshop.courses.duration_in_minutes', {minutes: course.duration}) }</div> : null }
	  					<div className="table__cell">{ additionalInformation(course.additional_information) }</div>
	  					<div className="table__cell course-table__action-button align-center">{ actions() }</div>
	  				</div>
	  			);
	  		};

	  		return (
	  			<div className="table">
	  				<div className="table__row table__header">
	  					{ headerItems }
	  					<div className="table__cell">&nbsp;</div>
	  				</div>

	  				{ courses.map((course, index) => {
	  					return courseRow(course, index);
	  				}) }
	  			</div>
	  		)
	  	};


	  	const weeklyCoursesBlocks = weeklyCourses ? Object.keys(weeklyCourses).map((key, index) => {
			return (
	  			<div className="page-section" key={ index } >
	  				<div className="content-section content-section--white">
	  					<div className="course-table">
	  						<div className="row">
	  							<div className="col-lg-12">
	  								<h4 className="sub-title sub-title--plain course-table__header">{ key }</h4>
	  							</div>
		  					</div>
		  					{ courseTable(weeklyCourses[key]) }
		  				</div>
	  				</div>
	  			</div>
	  		);
	  	}) : null;

	  	const campsBlocks = camps ? Object.keys(camps).map((key, index) => {
			return (
	  			<div className="page-section" key={ index } >
	  				<div className="content-section content-section--white">
	  					<div className="course-table">
	  						<div className="row">
	  							<div className="col-lg-12">
	  								<h4 className="sub-title sub-title--plain course-table__header">{ key }</h4>
	  							</div>
		  					</div>
		  					{ courseTable(camps[key]) }
		  				</div>
	  				</div>
	  			</div>
	  		);
	  	}) : null;

	  	const intensiveCoursesBlocks = intensiveCourses ? Object.keys(intensiveCourses).map((key, index) => {
			return (
	  			<div className="page-section" key={ index } >
	  				<div className="content-section content-section--white">
	  					<div className="course-table">
	  						<div className="row">
	  							<div className="col-lg-12">
	  								<h4 className="sub-title sub-title--plain course-table__header">{ key }</h4>
	  							</div>
		  					</div>
		  					{ courseTable(intensiveCourses[key]) }
		  				</div>
	  				</div>
	  			</div>
	  		);
	  	}) : null;

	  	const noCourses = <div className="content-section content-section--white">
	  		<div className="row">
	  			<div className="col-lg-12">
	  				<span className="bold">{ I18n.t('webshop.no_camps_or_intensive_courses_to_show') }</span>
	  			</div>
	  		</div>
	  	</div>;

	  	const noCoursesBlock = ((this.props.camps && Object.keys(camps).length) || (this.props.intensiveCourses && Object.keys(intensiveCourses).length)) ? null : noCourses;

	    return (
	      	<React.Fragment>
		      	{ infoBlock }

		      	{ filterBlock }
		      	
		      	{ weeklyCoursesBlocks }

		      	{ campsBlocks }

		      	{ intensiveCoursesBlocks }

		      	{ this.props.weeklyCourses ? null : noCoursesBlock }
	     	 </React.Fragment>
	    );
	 }
}

Schedule.propTypes = {
	intensiveCourses: 	PropTypes.object,
	camps: 				PropTypes.object,
	training: 			PropTypes.object, 
	weeklyCourses: 		PropTypes.object,
	seasons: 			PropTypes.array,
	filterCriteria: 	PropTypes.array,
	infoHtml: 			PropTypes.string,
	csrfToken: 			PropTypes.string.isRequired
};

export default Schedule;
